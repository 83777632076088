<template>
  <apexchart
    type="bar"
    :options="chartOptions"
    :series="series"
    style="height: 100px"
  ></apexchart>
    <!-- class="min-h-auto" -->
</template>
<script>
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  components: {
    // MapChart,
    apexchart: VueApexCharts,
  },
  name: "dashboard",
  data() {
    return {
      series: [
        {
          data: [100, 0, 0],
          show: !1,
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 150,
          toolbar: {
            show: !1,
          },
        },

        plotOptions: {
          bar: {
            horizontal: !1,
            columnWidth: ["22%"],
            borderRadius: 5,
            dataLabels: { position: "top" },
            startingShape: "flat",
          },
        },
        dataLabels: {
          enabled: !0,
          offsetY: -30,
          style: { fontSize: "13px", colors: ["labelColor"] },
          formatter: function (e) {
            return e + " %";
          },
        },
        legend: {
          show: !1,
        },
        colors: ["#3E97FF", "#F1416C", "#50CD89"],
        xaxis: {
          categories: ["Quizzes", "Surveys", "Forms"],
          labels: {
            formatter: function (e) {
              return e;
            },
            style: {
              colors: "#BDBDCA",
              fontSize: "12px",
              fontWeight: "600",
              align: "left",
            },
          },
          axisBorder: {
            show: !1,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#575A6A",
              fontSize: "12px",
              fontWeight: "600",
            },
            offsetY: 2,
            align: "left",
          },
        },
        grid: {
          borderColor: "#BDBDCA",
          xaxis: {
            lines: {
              show: !0,
            },
          },
          yaxis: {
            lines: {
              show: !1,
            },
          },
          strokeDashArray: 4,
        },
      },
    };
  },
});
</script>
