<template>
   <div class="row g-5 g-xl-10 mb-xl-10">
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
         <div class="card card-flush border-0 h-md-50 mb-5 mb-xl-10" style="background-color: #181C32">
            <div class="card-header pt-2">
               <h3 class="card-title">
                  <span class="text-white fs-3 fw-bolder me-2">Total Entries</span>
               </h3>
               <div class="card-toolbar">
                  <button class="btn btn-icon bg-white bg-opacity-10 btn-color-white btn-active-success w-25px h-25px"
                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                     <span class="svg-icon svg-icon-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black">
                           </path>
                           <path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                              fill="black"></path>
                        </svg>
                     </span>
                  </button>
                  <div
                     class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px"
                     data-kt-menu="true">
                     <div class="menu-item px-3">
                        <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                     </div>
                     <div class="separator mb-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                     </div>
                     <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a href="#" class="menu-link px-3">
                           <span class="menu-title">New Group</span>
                           <span class="menu-arrow"></span>
                        </a>
                        <div class="menu-sub menu-sub-dropdown w-175px py-4">
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Admin Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Staff Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Member Group</a>
                           </div>
                        </div>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                     </div>
                     <div class="separator mt-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <div class="menu-content px-3 py-3">
                           <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card-body d-flex justify-content-between flex-column pt-1 px-0 pb-0">
               <div class="d-flex flex-wrap ps-4 pe-6 mb-5">
                  <div class="rounded min-w-116px py-3 px-4 my-1 me-6 ms-2"
                     style="border: 1px dashed rgba(255, 255, 255, 0.15)">
                     <div class="d-flex align-items-center">
                        <div class="text-white fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="{{getTotalPieData.total_entries}}"
                           id="total_entries">{{getTotalPieData.total_entries}}</div>
                     </div>
                     <div class="fw-bold fs-7 text-white opacity-50">New leads</div>
                  </div>
                  <div class="rounded min-w-116px py-3 px-4 my-1" style="border: 1px dashed rgba(255, 255, 255, 0.15)">
                     <div class="d-flex align-items-center">
                        <div class="text-white fs-2 fw-bolder counted" data-kt-countup="true"
                           data-kt-countup-value="10">{{getTotalPieData.hot}}</div>
                     </div>
                     <div class="fw-bold fs-7 text-white opacity-50">Hot ({{getTotalPieData.hot}}%)</div>
                  </div>
                  <div class="rounded min-w-116px py-3 px-4 my-1 me-6 ms-2"
                     style="border: 1px dashed rgba(255, 255, 255, 0.15)">
                     <div class="d-flex align-items-center">
                        <div class="text-white fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="0"
                           id="top-qualified">{{getTotalPieData.qualified}}</div>
                     </div>
                     <div class="fw-bold fs-7 text-white opacity-50" id="top-perQual">Qual({{getTotalPieData.qualified}}%)</div>
                  </div>
                  <div class="rounded min-w-116px py-3 px-4 my-1" style="border: 1px dashed rgba(255, 255, 255, 0.15)">
                     <div class="d-flex align-items-center">
                        <div class="text-white fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="0"
                           id="top-disqualified">{{getTotalPieData.disqualified}}</div>
                     </div>
                     <div id="top-perdisq" class="fw-bold fs-7 text-white opacity-50"
                        style="font-size: 12px !important;">Disqual({{getTotalPieData.disqualified}}%)</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="card card-flush h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5">
               <div class="card-title d-flex flex-column">
                  <div class="d-flex align-items-center">
                     <span class="fs-2hx fw-bolder text-dark me-2 lh-1" id="m-perQual">{{getTotalPieData.perQual}}%</span>
                  </div>
                  <span class="text-gray-400 pt-1 fw-bold fs-6">Qualified Leads</span>
               </div>
            </div>
            <div class="card-body pt-2 pb-4 d-flex align-items-center">
               <div class="d-flex flex-center me-5 pt-2">
                  <PieChart :seriesData="getTotalPieData.pie_chart"/>
               </div>
               <div class="d-flex flex-column content-justify-center w-100" id="pie_chart_data">
                  <div class="d-flex fs-7 fw-bold align-items-center" v-for="(p,index) in getTotalPieData.pie_chart" :key="index">
                     <div class="bullet w-8px h-6px rounded-2 me-3"  :style="{backgroundColor: p.color}"></div>
                     <div class="text-gray-500 flex-grow-1 me-4">{{p.name}}</div>
                     <div class="fw-boldest text-gray-700 text-xxl-end">{{p.original_per}}%</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
         <div class="card card-flush h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5 ps-7">
               <div class="card-title d-flex flex-column">
                  <span class="fs-3 fw-bolder text-dark me-2">Questionaire Completion</span>
                  <span class="text-gray-400 pt-2 fw-bold fs-6">%campaigns Completed</span>
               </div>
            </div>
            <div class="card-body pt-5 ps-6">
               <!-- <div id="kt_charts_widget_6" class="min-h-auto" style="height: 100px;"></div> -->
               <BarChart/>
            </div>
         </div>
         <div class="card card-flush h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5">
               <div class="card-title d-flex flex-column">
                  <span class="fs-3 fw-bolder text-dark me-2 lh-1">Top Campaigns</span>
                  <span class="text-gray-400 pt-2 fw-bold fs-6">Campaigns with highest scores</span>
               </div>
            </div>
            <div class="card-body pt-5 ps-6">
               <HPieChart :top_cart_name="getCamContacts.top_cart_name" :top_cart_data="getCamContacts.top_cart_data" />
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
         <!--begin::Chart widget 3-->
         <div class="card card-flush overflow-hidden h-md-100">
            <!--begin::Header-->
            <div class="card-header pt-7 border-1px">
               <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-dark">
                     <span id="live_click_count">{{leadCount}}</span> Entries
                     <div class="bullet w-9px h-9px rounded-2 ms-1" style="background-color: rgb(241, 188, 0);"></div>
                  </span>
                  <span class="text-gray-400 pt-2 fw-bold fs-6">All Entries received Today </span>
               </h3>
               <div class="card-toolbar">
                  <ul class="nav custom-back">
                     <li class="nav-item"><a
                           class="nav-link btn btn-sm btn-color-muted btn-active btn-active-dark fw-bolder px-4 me-1 active"
                           data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">All</a></li>
                     <li class="nav-item"><a
                           class="nav-link btn btn-sm btn-color-muted btn-active btn-active-dark fw-bolder px-4 me-1"
                           data-bs-toggle="tab" href="#kt_table_widget_5_tab_2"> Quizzes </a></li>
                     <li class="nav-item"><a
                           class="nav-link btn btn-sm btn-color-muted btn-active btn-active-dark fw-bolder px-4 "
                           data-bs-toggle="tab" href="#kt_table_widget_5_tab_3"> Surveys</a></li>
                     <li class="nav-item"><a
                           class="nav-link btn btn-sm btn-color-muted btn-active btn-active-dark fw-bolder px-4 "
                           data-bs-toggle="tab" href="#kt_table_widget_5_tab_3">Forms</a></li>
                  </ul>
               </div>
            </div>
            <!--end::Header-->
            <!--begin::Card body-->
            <div class="card-body pt-5">
               <div class="row mb-2">
                  <div class="d-flex  align-items-center justify-content-between">
                     <div class="map-sector d-flex">
                        <label class="form-check-label pt-1 fw-bold me-1" for="allowmarketing">Map</label>
                        <div class="form-check form-check-solid form-switch fv-row ">
                           <input class="form-check-input w-45px h-30px" @change="handleSwitchMap($event)"
                              type="checkbox" id="allowmarketing"
                              style="width: 43px !important;height:23px !important;background-color: #EBBC42 !important;">
                           <label class="form-check-label pt-1 fw-bold " for="allowmarketing">Globe</label>
                        </div>
                     </div>
                     <div class="reset-button">
                        <span class="svg-icon svg-icon-3 btn btn-sm btn-light p-3" @click="resetData()">
                           <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32"
                              style="enable-background:new 0 0 32 32;" xml:space="preserve">
                              <g id="reset">
                                 <path id="reset_1_" d="M16,31.36C7.53,31.36,0.64,24.47,0.64,16S7.53,0.64,16,0.64c4.529,0,8.717,1.932,11.64,5.336V1h0.721v6.36
                                     H22V6.64h5.259C24.466,3.275,20.402,1.36,16,1.36C7.927,1.36,1.36,7.927,1.36,16c0,8.072,6.567,14.64,14.64,14.64
                                     c8.072,0,14.64-6.567,14.64-14.64h0.721C31.36,24.47,24.47,31.36,16,31.36z" />
                              </g>
                              <rect id="_Transparent_Rectangle" style="fill:none;" width="32" height="32" />
                           </svg>
                        </span>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <!--begin::Map container-->
                  <!-- <div id="map_chart" class="w-100 h-425px"></div> -->
                  <MapChart ref="mapchart" :seriesData="locationArray" @clearAll="clearMap"/>
               </div>
               <!--end::Map container-->
            </div>
            <!--end::Card body-->
         </div>
         <!--end::Chart widget 3-->
      </div>
   </div>
   <div class="row g-5 g-xl-10 mb-xl-10">
      <div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
         <!--begin::Chart widget 3-->
         <div class="card card-flush overflow-hidden h-md-100">
            <!--begin::Header-->
            <div class="card-header pt-5">
               <h3 class="card-title align-items-start flex-column"><span
                     class="card-label fw-bolder text-dark">Qualification</span><span
                     class="text-gray-400 pt-2 fw-bold fs-6">Overview 75% activity Growth</span></h3>
               <div class="card-toolbar">
                  <!--begin::Menu-->
                  <button class="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                     <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black"></rect>
                           <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                        </svg>
                     </span>
                  </button>
                  <!--begin::Menu 2-->
                  <div
                     class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px"
                     data-kt-menu="true">
                     <!--begin::Menu item-->
                     <div class="menu-item px-3">
                        <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                     </div>
                     <!--end::Menu item-->
                     <!--begin::Menu separator-->
                     <div class="separator mb-3 opacity-75"></div>
                     <!--end::Menu separator-->
                     <!--begin::Menu item-->
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                     </div>
                     <!--end::Menu item-->
                     <!--begin::Menu item-->
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                     </div>
                     <!--end::Menu item-->
                     <!--begin::Menu item-->
                     <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <!--begin::Menu item-->
                        <a href="#" class="menu-link px-3">
                           <span class="menu-title">New Group</span>
                           <span class="menu-arrow"></span>
                        </a>
                        <!--end::Menu item-->
                        <!--begin::Menu sub-->
                        <div class="menu-sub menu-sub-dropdown w-175px py-4">
                           <!--begin::Menu item-->
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Admin Group</a>
                           </div>
                           <!--end::Menu item-->
                           <!--begin::Menu item-->
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Staff Group</a>
                           </div>
                           <!--end::Menu item-->
                           <!--begin::Menu item-->
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Member Group</a>
                           </div>
                           <!--end::Menu item-->
                        </div>
                        <!--end::Menu sub-->
                     </div>
                     <!--end::Menu item-->
                     <!--begin::Menu item-->
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                     </div>
                     <!--end::Menu item-->
                     <!--begin::Menu separator-->
                     <div class="separator mt-3 opacity-75"></div>
                     <!--end::Menu separator-->
                     <!--begin::Menu item-->
                     <div class="menu-item px-3">
                        <div class="menu-content px-3 py-3">
                           <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                     </div>
                     <!--end::Menu item-->
                  </div>
                  <!--end::Menu 2-->
                  <!--end::Menu-->
               </div>
            </div>
            <!--end::Header-->
            <!--begin::Card body-->
            <div class="card-body">
               <!--begin::Map container-->
               <BubbleChart :seriesData="getTotalPieData" />
               <!--end::Map container-->
            </div>
            <!--end::Card body-->
         </div>
         <!--end::Chart widget 3-->
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
         <div class="card card-flush  h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5">
               <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-dark">General Leads</span>
                  <span class="text-gray-400 mt-1 fw-bold fs-6">Leads with Qualifications</span>
               </h3>
               <div class="card-toolbar">
                  <button class="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                     <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black"></rect>
                           <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                        </svg>
                     </span>
                  </button>
                  <div data-kt-menu="true"
                     class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px page_speed_712423729">
                     <div class="menu-item px-3">
                        <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                     </div>
                     <div class="separator mb-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                     </div>
                     <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a href="#" class="menu-link px-3">
                           <span class="menu-title">New Group</span>
                           <span class="menu-arrow"></span>
                        </a>
                        <div class="menu-sub menu-sub-dropdown w-175px py-4">
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Admin Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Staff Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Member Group</a>
                           </div>
                        </div>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                     </div>
                     <div class="separator mt-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <div class="menu-content px-3 py-3">
                           <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card-body pt-5" id="general_leads">
               <div v-for="(d,index) in getCamContacts.g_lead" :key="index">
               <div class="d-flex flex-stack">
                  <div class="d-flex align-items-center me-1">
                     <img :src="d.flag_url" alt="US"
                        class="me-4 w-20px page_speed_596590331">
                     <div class="me-5"><a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-7">{{d.name}}</a></div>
                  </div>
                  <span class="fw-bolder fs-7">{{d.data}}</span>
               </div>
               <div class="separator separator-dashed my-3"></div>
               </div>
            </div>
         </div>
         <div class="card card-flush  h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5">
               <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-dark">Qualifed Leads</span>
                  <span class="text-gray-400 mt-1 fw-bold fs-6">Leads Marked Qualifed</span>
               </h3>
               <div class="card-toolbar">
                  <button class="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                     <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black"></rect>
                           <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                        </svg>
                     </span>
                  </button>
                  <div data-kt-menu="true"
                     class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px page_speed_712423729">
                     <div class="menu-item px-3">
                        <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                     </div>
                     <div class="separator mb-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                     </div>
                     <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a href="#" class="menu-link px-3">
                           <span class="menu-title">New Group</span>
                           <span class="menu-arrow"></span>
                        </a>
                        <div class="menu-sub menu-sub-dropdown w-175px py-4">
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Admin Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Staff Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Member Group</a>
                           </div>
                        </div>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                     </div>
                     <div class="separator mt-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <div class="menu-content px-3 py-3">
                           <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card-body pt-5" id="q_leads">
               <div v-for="(d,index) in getCamContacts.q_lead" :key="index">
               <div class="d-flex flex-stack">
                  <div class="d-flex align-items-center me-1">
                     <img :src="d.flag_url" alt="US"
                        class="me-4 w-20px page_speed_596590331">
                     <div class="me-5"><a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-7">{{d.name}}</a></div>
                  </div>
                  <span class="fw-bolder fs-7">{{d.data}}</span>
               </div>
               <div class="separator separator-dashed my-3"></div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
         <div class="card card-flush  h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5">
               <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-dark">Disqualifed Leads</span>
                  <span class="text-gray-400 mt-1 fw-bold fs-6">Leads Marked Disqualifed</span>
               </h3>
               <div class="card-toolbar">
                  <button class="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                     <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black"></rect>
                           <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                        </svg>
                     </span>
                  </button>
                  <div data-kt-menu="true"
                     class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px page_speed_712423729">
                     <div class="menu-item px-3">
                        <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                     </div>
                     <div class="separator mb-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                     </div>
                     <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a href="#" class="menu-link px-3">
                           <span class="menu-title">New Group</span>
                           <span class="menu-arrow"></span>
                        </a>
                        <div class="menu-sub menu-sub-dropdown w-175px py-4">
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Admin Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Staff Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Member Group</a>
                           </div>
                        </div>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                     </div>
                     <div class="separator mt-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <div class="menu-content px-3 py-3">
                           <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card-body pt-5" id="dis_lead">
               <div v-for="(d,index) in getCamContacts.dis_leads" :key="index">
               <div class="d-flex flex-stack">
                  <div class="d-flex align-items-center me-1">
                     <img :src="d.flag_url" alt="US"
                        class="me-4 w-20px page_speed_596590331">
                     <div class="me-5"><a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-7">{{d.name}}</a></div>
                  </div>
                  <span class="fw-bolder fs-7">{{d.data}}</span>
               </div>
               <div class="separator separator-dashed my-3"></div>
               </div>
            </div>
         </div>
         <div class="card card-flush  h-md-50 mb-5 mb-xl-10">
            <div class="card-header pt-5">
               <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-dark">Hot Leads</span>
                  <span class="text-gray-400 mt-1 fw-bold fs-6">Latest social statistics</span>
               </h3>
               <div class="card-toolbar">
                  <button class="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                     <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black"></rect>
                           <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                           <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black"></rect>
                        </svg>
                     </span>
                  </button>
                  <div data-kt-menu="true"
                     class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px page_speed_712423729">
                     <div class="menu-item px-3">
                        <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                     </div>
                     <div class="separator mb-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                     </div>
                     <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a href="#" class="menu-link px-3">
                           <span class="menu-title">New Group</span>
                           <span class="menu-arrow"></span>
                        </a>
                        <div class="menu-sub menu-sub-dropdown w-175px py-4">
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Admin Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Staff Group</a>
                           </div>
                           <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3">Member Group</a>
                           </div>
                        </div>
                     </div>
                     <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                     </div>
                     <div class="separator mt-3 opacity-75"></div>
                     <div class="menu-item px-3">
                        <div class="menu-content px-3 py-3">
                           <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card-body pt-5">
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import { defineComponent, onMounted } from "vue";
   import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
   import MapChart from '@/components/custom-components/chart/map.vue'
   import BarChart from '@/components/custom-components/chart/barChart.vue'
   import PieChart from '@/components/custom-components/chart/pieChart.vue'
   import HPieChart from '@/components/custom-components/chart/horizontalBarChart.vue'
   import BubbleChart from '@/components/custom-components/chart/bubbleChart.vue'
   import {mapActions,mapGetters} from 'vuex'
   export default defineComponent({
      components:{
         MapChart,
         BarChart,
         PieChart,
         HPieChart,
         BubbleChart
      },
      name: "dashboard",
      setup() {
         onMounted(() => {
            setCurrentPageTitle("Dashboard");
         });
      },
      data(){
         return{
            locationArray:[],
            leadCount:0
          }
      },
      computed:{
         ...mapGetters({
            getTotalPieData:'Dashboard/getTotalPieData',
            getCamContacts:'Dashboard/getCamContacts',
            user:"Auth/user"
         })
      },
      created(){
         this.getTotalPieChartData()
         this.getMapData().then((res)=>{
            if(res.data.status== true){
               console.log(res.data.data)
               this.locationArray=res.data.data
               this.leadCount=this.locationArray.length
            }
         })
         this.getTopCampaignContact()
            var pusher = new window.Pusher('cb095aa87ba108872914', {
               cluster: 'us3'
            });
         var self=this // eslint-disable-line
         var channel = pusher.subscribe('get-new-lead-channel-quizfarma.'+this.user.id);
         channel.bind('LEAD_GENERATED', function(data) {
            self.getTotalPieChartData();
            self.getTopCampaignContact();
            self.addMapData(data.leadData);
         });
         let data = {
            page: 1,
            sort_group_id: "",
            query: "",
            };
            this.setgetQuiz(data)
      },
      methods:{
         handleSwitchMap(e){
            this.$refs.mapchart.handleSwitchMap(e)
         },
         resetData(){
            this.$refs.mapchart.resetData()  
         },
         clearMap(){
            this.locationArray=[]
            this.leadCount=0
         },
         addMapData(data){
            this.$refs.mapchart.addNewData(data)
            this.leadCount++
         },
         ...mapActions({
            getTotalPieChartData:'Dashboard/getTotalPieChartData',
            getMapData:'Dashboard/getMapData',
            getTopCampaignContact:'Dashboard/getTopCampaignContact',
            setgetQuiz: "Quiz/getQuiz",
         })
      },
      });

</script>
<style>
   .min-w-116px {
      min-width: 116px !important;
   }

   .border-1px {
      border-bottom: 1px solid #EFF2F5 !important;
   }

   .general-class {
      background-color: #F1416C !important;
   }

   .Hot-class {
      background-color: #E4E6EF !important;
   }

   .Qual-class {
      background-color: #E38600 !important;
   }

   .Disqual-class {
      background-color: #5470eb !important;
   }
</style>